// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/background.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/satoshi_icon.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./bitcoin_generate_qr_code.scss"></require>
  <require from="../../../components/card-banka-detailled/card-banka-detailled"></require>
  <require from="../../../components/header-standard/header-standard"></require>
  <require from="../../../components/ValueConverters/currency-denomination"></require>
  <require from="../../../components/ValueConverters/currency-formatter/currency-formatter"></require> 
  <require from="../../../components/number-format"></require>
  <section id="bitcoin_generate_qr_code" class="flex-column">
    <header-standard show-back-button.bind="showBackButton" show-message.bind="showMessage" message.bind="message" show-logo.bind="showLogo" show-language.bind="showLanguage"></header-standard>    
    <div class="flex-grow"></div>
    <div class="canvas">
      <img src="${___HTML_LOADER_IMPORT_0___}" if.bind="!qrcode_canvas">
      <canvas id="qrcode_canvqas" ref="qrcode_canvas" if.bind="qrCodeAmount || qrCodeReference"></canvas>
    </div>
    </div>
    <div class="flex-grow"></div>
    <form id="payment_price" class="flex-row"
      class.bind="(isSelectingRecipient || isSelectingSender) ? 'selecting' : ''"
      submit.delegate="gotToQrCodeReceive()">
      <input disabled.bind="locked" id="qrCodeAmount" style="margin-left: 1.3rem;" type="number" step="0.01" min="0" value.bind="qrCodeAmount"
        placeholder="0" required="required" class.bind="isAmountError ? 'error' : ''" />
        <img src="${___HTML_LOADER_IMPORT_1___}" style="margin-right: 1.3rem;">
      </form>
    <form id="payment_reference" class="flex-row"
      class.bind="(isSelectingRecipient || isSelectingSender) ? 'selecting' : ''">
      <input disabled.bind="locked" ref="input_reference" id="qrCodeReference" type="text" value.bind="qrCodeReference"
        value.bind="referenceStruct" placeholder.bind="getRandomCommunication()"
        class.bind="isCommunicationError ? 'error' : ''" focus.delegate="input_reference.placeholder='no'"
         onkeypress="return /[0-9a-zA-Z- ]/i.test(event.key)" input.delegate="sanitizeCommentary(qrCodeReference)">
    </form>
    <div class="flex-grow"></div>
    <div class="confirm">
      <button class="btn btn-primary" if.bind="qrCodeAmount" click.delegate="goToQrCodeReceive()" i18n="home.next"
        form="payment_price && payment_reference" >Suivant</button>
      <button class="btn btn-primary fakeDisabled" if.bind="!qrCodeAmount" click.delegate="shakeError()"
        t="home.next"></button>
    </div>
    <div class="spacer-16"></div>
  </section>
</template>
`;
// Exports
export default code;